var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-submission-form" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v("\n    Submit to showcase\n  "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "required" }, [
        _vm._v("\n    All fields marked * are required.\n  "),
      ]),
      _vm._v(" "),
      _c(
        "FormRoot",
        {
          attrs: {
            "form-id": "showcase-submission",
            "enable-submit": !_vm.submitting,
            "submit-button-text": `${
              _vm.submitting ? "Submitting..." : "Submit project"
            }`,
          },
        },
        [
          _c(
            "fieldset",
            [
              _c("legend", { staticClass: "subhead-2" }, [
                _vm._v("\n        Project details\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "user-info" },
                [
                  _c("TextInput", {
                    ref: "firstName",
                    attrs: {
                      model: _vm.firstName,
                      label: "First name",
                      required: "true",
                      validate: "true",
                      "hide-asterisk": false,
                      disabled: "true",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.firstName = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("TextInput", {
                    ref: "lastName",
                    attrs: {
                      model: _vm.lastName,
                      label: "Last name",
                      required: "true",
                      validate: "true",
                      "hide-asterisk": false,
                      disabled: "true",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.lastName = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("TextInput", {
                    ref: "email",
                    attrs: {
                      model: _vm.email,
                      label: "Email address",
                      required: "true",
                      validate: "true",
                      "hide-asterisk": false,
                      disabled: "true",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.email = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("TextInput", {
                    ref: "title",
                    attrs: {
                      placeholder: "Tell us the title of the project.",
                      model: _vm.title,
                      required: "true",
                      validate: "true",
                      label: "Project title",
                      maxlength: _vm.maxTitleChars,
                      "help-text": _vm.titleCharsLeft + " characters left",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.title = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("TextareaInput", {
                    ref: "objective",
                    attrs: {
                      placeholder:
                        "Tell us about the goals of the project as it relates to the organizations overall priorities and objectives.",
                      model: _vm.projectObjective,
                      required: "true",
                      validate: "true",
                      maxlength: _vm.maxObjectiveChars,
                      rows: 10,
                      label: "Project objective",
                      "help-text": _vm.objectiveCharsLeft + " characters left",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.projectObjective = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("label", [_vm._v("Business line / function *")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "caption" }, [
                    _vm._v("\n          Select all that apply.\n        "),
                  ]),
                  _vm._v(" "),
                  _c("CheckboxArrayInput", {
                    ref: "businessLineFunction",
                    attrs: {
                      name: "businessLineFunction",
                      model: _vm.businessLineFunction,
                      list: _vm.businessLineFunctionSelectList,
                      required: "true",
                      validate: "true",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.businessLineFunction = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "user-info" },
                [
                  _c("label", [_vm._v("Audience *")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "caption" }, [
                    _vm._v("\n          Select all that apply.\n        "),
                  ]),
                  _vm._v(" "),
                  _c("CheckboxArrayInput", {
                    ref: "audience",
                    attrs: {
                      name: "audience",
                      model: _vm.audience,
                      list: _vm.audienceSelectList,
                      validate: "true",
                      required: "true",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.audience = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "audienceInsight",
                attrs: {
                  model: _vm.audienceInsight,
                  required: "false",
                  validate: "true",
                  placeholder:
                    "Tell us about the audience or industry insight(s) that inspired this creative work.",
                  maxlength: _vm.maxInsightChars,
                  rows: 10,
                  label: "Audience insight or inspiration for design",
                  "help-text": _vm.insightCharsLeft + " characters left",
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.audienceInsight = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "teamMember",
                attrs: {
                  placeholder:
                    "Provide the names and roles of all team members involved in the project (example: Jane Doe, Project Manager)",
                  model: _vm.teamMember,
                  required: "true",
                  validate: "true",
                  maxlength: _vm.maxTeamMemberChars,
                  label: "Team member names",
                  "help-text": _vm.teamMemberCharsLeft + " characters left",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.teamMember = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("label", [_vm._v("\n        Upload 1-10 images*\n      ")]),
              _vm._v(" "),
              _c("p", { staticClass: "caption image-upload" }, [
                _vm._v(
                  "\n        Attach images or video files here.  Total size of files must not exceed 2GB.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("ShowcaseFileUpload", {
                ref: "fileUpload",
                attrs: {
                  "upload-button-text": "Add file(s)",
                  "upload-files-demo": "uploadFilesDemo",
                },
                on: { "send-hash-collection": _vm.onHashCollectionReady },
              }),
              _vm._v(" "),
              _c("showcase-links", {
                ref: "showcaseLinks",
                attrs: {
                  links: _vm.links,
                  placeholder_text: "e.g. Policies",
                  placeholder_url: "e.g. http://www.site.com/link",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }